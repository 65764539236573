export const LOCAL_STORAGE_PROPERTY_LABEL = 'dg-chat-state';
export const CHAT_SESSION_ID_PROPERTY_LABEL = 'DG_CHAT_SESSION_ID';
export const CHAT_DIALOG_VISIBILITY_PROPERTY_LABEL = 'DG_CHAT_DIALOG_VISIBLE';
export const SERVER_STORAGE_COOKIE_LABEL = 'dg-chat-session-id';
export const DG_CHAT_CDN_URL = 'chat-assets.digitalgenius.com';

export enum MEMBERS {
  USER = 'customer',
  BOT = 'ai',
  AGENT = 'agent',
  SYSTEM = 'system',
}

export enum CSAT_TYPE {
  STARS = 'stars',
  EMOJIS = 'emojis',
  SMILES = 'smiles',
  THUMBS = 'thumbs',
}

// timeouts
export const INACTIVE_TAB_NOTIFICATIONS_TIMER = 500;

export const ANIMATION_TIME = 300;

export enum MESSAGE_TYPES {
  MESSAGE = 'message',
  EVENT = 'event',
  QUICK_REPLY = 'quickReply',
  USER_QUICK_REPLY = 'userQuickReply',
  CAROUSEL = 'carousel',
  USER_CAROUSEL_REPLY = 'userCarouselReply',
  ATTACHMENT = 'attachment',
  PRECHAT_FORM = 'prechatForm',
  PROACTIVE_BUTTONS_RESPONSE = 'proactiveButtonsReply',
  WIDGET_ANALYTICS = 'widgetAnalytics',
  SELECT_DROPDOWN = 'selectDropdown',
  BATCH = 'batch',
  CHAT_EVENT = 'chat_event',
  SYSTEM_EVENT = 'system_event',
  TYPING_START = 'typing_start',
  TYPING_STOP = 'typing_stop',
  START = 'start',
  END = 'end',
  FLOW_ERROR = 'error',
  RESTART = 'restart',
  CLOSE = 'close',
  ERROR = 'error',
  HANDOVER_ERROR = 'handover_error',
  AGENT_JOINING = 'joining',
  AGENT_JOINED = 'joined',
  AGENT_DISCONNECTED = 'disconnect',
  AGENT_TRANSFERRED = 'transferred',
  QUEUE_UPDATE = 'queue_update',
  FILE_TRANSFER_REQUEST = 'file_transfer_request',
  FILE_TRANSFER_CANCEL = 'file_transfer_cancel',
  TIMEOUT = 'timeout',
  BOT_CSAT_REQUEST = 'sendCSATRequest', // CSAT requests as a bot message (page refresh / new tab)
  CSAT_REQUEST = 'csat_request', // CSAT requests as an event
  CSAT_PROVIDED = 'csat_provided',
  CSAT_NOT_PROVIDED = 'csat_not_provided',
  CSAT_COMMENT = 'csat_comment',
  UPLOAD_FILE_REQUEST = 'uploadFileRequest',
  UPLOAD_PICTURE_REQUEST = 'uploadPictureRequest',
  IDLE_TIMEOUT_COUNTDOWN = 'idle_timeout_countdown',
  IDLE_TIMEOUT = 'idle_timeout',
  CRM_HANDOVER = 'crmHandover',
  MESSAGE_FEEDBACK = 'message_feedback',
  UNASSIGNED = 'unassigned',
  CAROUSEL_SELECTION = 'carousel_selection',
  HELPDESK_HANDOVER = 'helpdesk_handover',
  PAGE_NAVIGATION = 'page_navigation',
  FORM = 'form',
  FORM_SUBMISSION = 'form_submission',
  BOT_FORM_CLOSED = 'BOT_FORM_CLOSED',
  SYSTEM_MESSAGE = 'system_message',
  LIST = 'list',
  LIST_SELECTION = 'list_selection',
  LIST_CLOSED = 'list_closed',
  DROPDOWN_SELECTION = 'dropdown_selection',
}

export const ERROR_CODES = {
  TOO_BIG_FILE: 413,
  VIRUS: 406,
  GENERIC: 'GENERIC',
};

export const MAXIMUM_FILE_SIZE = 16000000;

export enum STORAGE {
  LOCAL_STORAGE = 'localstorage',
  COOKIE = 'cookie',
}

export enum MemberTypes {
  USER = 'customer',
  BOT = 'ai',
  AGENT = 'agent',
}

export enum MemberState {
  OFF,
  JOINING,
  IDLE,
  TYPING,
}

export enum MessageScore {
  NEGATIVE,
  POSITIVE,
}

export enum SdkPlatform {
  SUNCO = 'sunco',
  GORGIAS = 'gorgias',
}

export enum PrechatFormVariant {
  SOLID = 'solid',
  GRADIENT = 'gradient',
  DIAGONAL = 'diagonal',
  HORIZONTAL = 'horizontal',
}

export const callbacks = window.parent?.DG_CHAT_WIDGET_CONFIG?.callbacks || {};

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
