const globalStyles = () => {
  const {
    desktop: widgetPositionDesktop, mobile: widgetPositionMobile,
  } = window.DG_CHAT_WIDGET_CONFIG?.widgetPosition;
  const {
    desktop: widgetDimensionsDesktop, mobile: widgetDimensionsMobile,
  } = window.DG_CHAT_WIDGET_CONFIG?.widgetDimensions;

  const widgetDialogDimensionsDesktop = widgetDimensionsDesktop?.dialog;
  const widgetDialogDimensionsMobile = widgetDimensionsMobile?.dialog;

  const widgetPositionDesktopDialog = widgetPositionDesktop?.dialog;
  const widgetPositionDesktopLauncher = widgetPositionDesktop?.launcher;
  const widgetPositionDesktopProactive = widgetPositionDesktop?.proactive;

  const widgetPositionMobileDialog = widgetPositionMobile?.dialog;
  const widgetPositionMobileLauncher = widgetPositionMobile?.launcher;
  const widgetPositionMobileProactive = widgetPositionMobile?.proactive;

  return `
  @keyframes slidein {
    from {
      opacity: 0;
      transform: translateY(60%);
    }

    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  @keyframes slideout {
    from {
      opacity: 1;
      transform: translateY(0%);
    }

    to {
      opacity: 0;
      transform: translateY(60%);
    }
  }
  #dg-chat-iframe {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    top: 0;
    left: 0;
    border: none;
    display: block;
    z-index: -1;
    pointer-events: none;
  }
  #dg-chat-container {
    position: relative;
    z-index: 2147483647;
  }
  #dg-chat-widget {
    position: fixed;
    width: 100%;
    right: 20px;
    bottom: 20px;
    top: ${widgetPositionDesktopDialog.top || 'initial'};
    right: ${widgetPositionDesktopDialog.right || 'initial'};
    bottom: ${widgetPositionDesktopDialog.bottom || 'initial'};
    left: ${widgetPositionDesktopDialog.left || 'initial'};
    max-width: ${widgetDialogDimensionsDesktop.width};
    height: 90%;
    max-height: ${widgetDialogDimensionsDesktop.height};
    min-height: 300px;
    box-shadow: rgb(0 0 0 / 12%) 0 12px 48px 4px;
    border-radius: 16px;
    overflow: hidden;
  }
  #dg-chat-widget.dg-animation-in,
  #dg-chat-widget-proactive.dg-animation-in,
  #dg-chat-widget-launcher.dg-animation-in {
    animation: slidein ease-in-out;
  }
  #dg-chat-widget.dg-animation-out,
  #dg-chat-widget-proactive.dg-animation-out,
  #dg-chat-widget-launcher.dg-animation-out {
    animation: slideout ease-in-out;
  }
  @media only screen and (max-width: ${window.DG_CHAT_WIDGET_CONFIG?.theme?.breakpoints?.mobile || '800px'}) {
    #dg-chat-widget {
      top: ${widgetPositionMobileDialog.top || 'initial'};
      bottom: ${widgetPositionMobileDialog.bottom || 'initial'};
      left: ${widgetPositionMobileDialog.left || 'initial'};
      right: ${widgetPositionMobileDialog.right || 'initial'};
      height: ${widgetDialogDimensionsMobile.height};
      width: ${widgetDialogDimensionsMobile.width};
      max-width: initial;
      max-height: 100vh;
      min-height: initial;
      border-radius: 0;
    }
  }
  #dg-chat-widget-launcher {
    position: fixed;
    top: ${widgetPositionDesktopLauncher.top || 'initial'};
    right: ${widgetPositionDesktopLauncher.right || 'initial'};
    bottom: ${widgetPositionDesktopLauncher.bottom || 'initial'};
    left: ${widgetPositionDesktopLauncher.left || 'initial'};
    height: 75px;
    width: 70px;
  }
  @media only screen and (max-width: ${window.DG_CHAT_WIDGET_CONFIG?.theme?.breakpoints?.mobile || '800px'}) {
    #dg-chat-widget-launcher {
      top: ${widgetPositionMobileLauncher.top || 'initial'};
      right: ${widgetPositionMobileLauncher.right || 'initial'};
      bottom: ${widgetPositionMobileLauncher.bottom || 'initial'};
      left: ${widgetPositionMobileLauncher.left || 'initial'};
    }
  }
  #dg-chat-widget-proactive {
    position: fixed;
    top: ${widgetPositionDesktopProactive.top || 'initial'};
    right: ${widgetPositionDesktopProactive.right || 'initial'};
    bottom: ${widgetPositionDesktopProactive.bottom || 'initial'};
    left: ${widgetPositionDesktopProactive.left || 'initial'};
  }
  @media only screen and (max-width: ${window.DG_CHAT_WIDGET_CONFIG?.theme?.breakpoints?.mobile || '800px'}) {
    #dg-chat-widget-proactive {
      top: ${widgetPositionMobileProactive.top || 'initial'};
      right: ${widgetPositionMobileProactive.right || 'initial'};
      bottom: ${widgetPositionMobileProactive.bottom || 'initial'};
      left: ${widgetPositionMobileProactive.left || 'initial'};
    }
  }

  #dg-chat-widget-attachment-preview {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  #web-messenger-container {
    display: none;
  }

  .dg-chat-modal-open {
    overflow: hidden;
  }
`;
};

export default globalStyles;
