import { PrechatFormVariant } from '@constants';
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    brand: '#303030',
    primary: '#000',
    secondary: '#fff',
    error: '#c70000',
    welcomeScreenBg: '#fff',
    positiveMessageFeedback: '#11a193',
    negativeMessageFeedback: '#ad020b',
  },
  carousel: {
    slideBorderColour: '#e6e6e6',
    arrowsColour: '#000',
    arrowsCircleColour: '#fff',
    displayArrowsOnMobile: false,
    priceBorderColour: '#fff',
    priceTextColour: '#000',
  },
  listPopup: {
    backgroundHoverColour: '#f9f9f9',
    borderColour: '#eeeeee',
    descriptionTextColour: '#6d6d6d',
  },
  chatInput: {
    borderColour: '#858585',
    borderHoverColour: '#000',
    sendButtonIconColour: '#262626',
    messageLimitTextColour: '#4d4d4d',
    uploadButtonIconColour: '#7F7F7F',
    uploadButtonIconHoverColour: '#262626',
  },
  prechatForm: {
    variant: PrechatFormVariant.SOLID,
    welcomeMessageTextColour: '#fff',
    inputTextColour: '#2B2B2B',
    inputBackgroundColour: '#F3F3F3',
    suffixTextColour: '#2B2B2B',
    iconsColour: '#fff',
  },
  breakpoints: {
    mobile: '800px',
  },
};

export { theme as default };
