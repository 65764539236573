import merge from 'lodash.merge';
import { DGChatWidgetConfig } from '@types';
import defaultConfig from '@config/index';
import globalStyles from '@config/globalStyles';

window.dgchat = {
  injectIntoIframeHead: (contentDocument: HTMLIFrameElement['contentDocument'], src: string) => {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', src);

    contentDocument?.head.appendChild(script);
  },
  init: async () => {
    const { env, widgetId } = window.DG_CHAT_WIDGET_CONFIG;
    try {
      const response = await fetch(`https://flow-server.${env}.dgdeepai.com/chat-widget/${widgetId}/public`);
      const data = await response.json() as DGChatWidgetConfig;

      // widget config from the server overrides the default config
      // config defined on the window overrised the server config
      window.DG_CHAT_WIDGET_CONFIG = merge({}, defaultConfig, data, window.DG_CHAT_WIDGET_CONFIG);

      const generatedGlobalStyles = globalStyles();

      // Create an iframe which will get injected in to the customers page.
      // Inside that iframe inject the widget script and any other scripts we need to
      // this enables the widget script to run sanboxed inside the iframe and cannot
      // be affected or affect any javascript on the customers website.
      // The widget script will then inject 3 iframes containing the widget launcher,
      // the widget proactive buttons and the widget dialog in to the parent frame (the
      // customers website).
      const iframe = document.createElement('iframe');
      iframe.id = 'dg-chat-iframe';
      document.body.appendChild(iframe);
      const iframeDocument = iframe.contentDocument;

      // we have to manually write the iframe doc like this of current version of Firefox
      // won't let us inject scripts
      iframeDocument?.open();
      iframeDocument?.write('<!DOCTYPE html><head><title>DigitalGenius Chat</title><body></body></html>');
      iframeDocument?.close();

      // inject the chat widget script
      window.dgchat.injectIntoIframeHead(
        iframeDocument,
        './dgchat_dev.js',
      );

      // Now inject the global chat widget styles in to the customer document head.
      // Ensure to PREPEND the stylesheet so that the customer is able to add their own stylesheet
      // which can override the css defined in globalStyles
      const style = document.createElement('style');
      style.innerHTML = generatedGlobalStyles;
      document.head.prepend(style);
    } catch (error) {
      console.error(error);
    }
  },
};
