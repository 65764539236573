const lang = {
  sendingUserMessageText: 'Sending...',
  attachmentsMenuFile: 'Add File',
  attachmentsMenuPicture: 'Add Picture',
  attachmentsMenuLocation: 'Add Location',
  dragAndDropMessage: 'Drag and drop file here',
  header: '',
  chatInputPlaceholder: 'Type message here',
  prechatButtonText: 'Start Chat',
  prechatFormSelectPlaceholderText: 'Select',
  prechatConfirmationMessage: 'Give us few minutes to find your order',
  messageLimitErrorText: 'Oops, that message is too long',
  endChatHeader: 'End Chat',
  endChatQuestion: 'Are you sure you want to end chat?',
  endChatConfirmButton: 'End chat',
  endChatCancelButton: 'Cancel',
  carouselSelectionButtonText: 'Select',
  idleTimeoutPrefixMessage: 'Are you still there? Please send a message within',
  idleTimeoutSuffixMessage: 'or this chat will time out',
  returnToMainMenu: 'Return to main menu',
  messageFeedbackText: 'Was this helpful?',
  userClosedChatMessageToAgent: '*** USER CLOSED CHAT. ***',
  listPopupSubmitButtonText: 'Confirm',
  agent: {
    agentEndedChat: 'Agent left the chat',
    agentDisconnected: 'The agent disconnected',
    agentJoined: 'An agent joined the chat',
    namedAgentEndedChat: 'left the chat',
    namedAgentDisconnected: 'disconnected',
    namedAgentJoined: 'joined the chat',
    agentTransferredChat: 'You are being transferred to another agent',
    queueMessageWithPosition: 'You are in a queue. Your position is',
    queueMessage: 'You are in a queue.',
    agentJoining: 'An agent is joining',
    couldNotConnect: 'Could not connect to any agents',
    connectingToAnAgent: 'Connecting you with an agent',
    reconnectingToAnAgent: 'Attempting to reconnect to an agent',
    couldNotUploadFile: 'There was an problem uploading your file. Please try again.',
    disconnectedReconnecting: 'You were disconnected. Connecting you to another agent',
    automatedAgentMessage: 'An agent will assist you shortly',
    chatEndedDueToInactivity: 'Chat ended due to inactivity',
    chatConnectionLost: 'Chat ended due to loss of connection',
  },
  flow: {
    defaultErrorMessage: 'We were unable to handle your request. Please try again',
  },
  fileUploadErrors: {
    virus: {
      title: 'Oops!',
      descr: 'This file may contain a virus or other malware and can\'t be uploaded. Try another file.',
    },
    tooLarge: {
      title: 'Too Big!',
      descr: 'Files that are more than 16MB cannot be uploaded. Try another file.',
    },
    generic: {
      title: 'Oops!',
      descr: 'There was a problem uploading this file. Try another file.',
    },
  },
  newMessageNotifyText: 'New Message',
  poweredByDigitalGenius: {
    poweredBy: 'Powered by',
    logo: {
      digital: 'Digital',
      genius: 'Genius',
    },
  },
  csatLang: {
    ai: {
      csatNotice: null,
      csatTitle: 'Please rate this chat',
    },
    agent: {
      csatNotice: 'Agent has ended the chat.',
      csatTitle: 'Please rate this conversation',
    },
    customer: {
      csatNotice: null,
      csatTitle: 'Please rate this chat before you go',
    },
    csatFeedbackTitle: 'Thank you for sharing your feedback',
    ratingsText: null,
    csatFeedbackButtonText: 'Submit',
    csatFeedbackTextareaPlaceholder: 'Add any additional comments here.',
  },
  internetConnectionLost: 'Your internet connection appears to have been lost. Please check and try again.',
  internetConnectionRestored: "You're back online!",
};

export { lang as default };
