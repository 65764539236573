import { DGChatConfig } from '@types';
import { CSAT_TYPE, DG_CHAT_CDN_URL, STORAGE } from '@constants';
import lang from './lang';
import theme from './theme';
import icons from './icons';

const defaultConfig: DGChatConfig = {
  prechatForm: {
    isEnabled: false,
    fields: [],
  },
  proactiveButtonsSettings: {
    isEnabled: false,
    questions: [],
    answers: [],
  },
  csat: {
    isEnabled: true,
    type: CSAT_TYPE.EMOJIS,
    commentsCharacterLimit: 320,
    iconColors: {
      thumbs: '#5a5a5a',
      smiles: '#5a5a5a',
      stars: '#FFE26A',
    },
    iconActiveColors: {
      thumbs: ['#AD020B', '#11A193'],
      smiles: ['#AD020B', '#D36204', '#EDA92B', '#5E0898', '#11A193'],
      stars: '#FFE26A',
    },
  },
  widgetDimensions: {
    desktop: {
      dialog: { width: '375px', height: '650px' },
    },
    mobile: {
      dialog: { width: '100%', height: '100%' },
    },
  },
  widgetPosition: {
    desktop: {
      launcher: { bottom: '10px', right: '10px' },
      proactive: { bottom: '90px', right: '20px' },
      dialog: { bottom: '20px', right: '20px' },
    },
    mobile: {
      launcher: { bottom: '10px', right: '10px' },
      proactive: { bottom: '90px', right: '20px' },
      dialog: {
        top: '0px', right: '0px', bottom: '0px', left: '0px',
      },
    },
  },
  generalSettings: {
    hasSendButton: true,
    hasDragAndDropAttachment: true,
    hasCloseButton: true,
    headerIcon: '',
    isDialogMaximised: false,
    isChatCloseControlEnabled: true,
    isChatMinimizeControlEnabled: true,
    maskCreditCardNumbers: true,
    isAnimationDisabled: false,
    enableInactiveTabNotifications: true,
    isChatLauncherEnabled: true,
    hasDGPoweredBy: true,
    enableDGPoweredByLink: true,
    delayBetweenBotMessages: 1000,
    enableCrmUserAttachments: true,
    enableNavigationTracking: false,
    showDownloadAttachmentsButton: true,
    disableAnimations: false,
  },
  sessionStorageMechanism: {
    mechanism: STORAGE.LOCAL_STORAGE,
    domain: null,
  },
  styles: {
    icons,
    widgetStyles: {},
    fonts: [{
      fontFamily: 'Avenir Light',
      type: 'woff2',
      url: `https://${DG_CHAT_CDN_URL}/avenir-light.woff`,
      role: 'primary',
    }],
    systemFonts: [{
      fontFamily: 'Avenir Book',
      type: 'woff2',
      url: `https://${DG_CHAT_CDN_URL}/avenir-book.woff`,
      role: 'system',
    }, {
      fontFamily: 'Avenir Medium',
      type: 'woff2',
      url: `https://${DG_CHAT_CDN_URL}/avenir-medium.woff`,
      role: 'system',
    },
    {
      fontFamily: 'Avenir Heavy',
      type: 'woff2',
      url: `https://${DG_CHAT_CDN_URL}/avenir-heavy.woff`,
      role: 'system',
    }],
  },
  theme,
  lang,
  metadata: {},
  isDebug: false,
};

export default defaultConfig;
